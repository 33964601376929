import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants";
import { deleteIcon } from "../../../../icons";
import moment from "moment";

const VersionProfileList = ({
  version_id,
  list,
  tableHeaders,
  handleDelete,
}) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <div className="">
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  {tableHeaders.map((item, index) => {
                    return (
                      <th
                        className="custom_data_table_heading text-center"
                        key={index}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {list?.map((item, i) => (
                  <tr className="custom_data_table_row" key={item.id}>
                    <td className="custom_data_table_item table_item">
                      {i + 1}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.profile.name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.total_bytes}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.profile.created_at &&
                        moment(item.profile.created_at).format("DD/MM/YYYY")}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <Link
                          className="comman_btn"
                          to={`/dtv-version-profile/view/${item.profile.id}`}
                          state={{ item: item, version_id: version_id }}
                        >
                          View
                        </Link>
                        <button
                          aria-label="edit"
                          className="custom_data_tabl_delete_item_btn"
                          onClick={(e) => handleDelete(item)}
                        >
                          {deleteIcon}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default VersionProfileList;
