import React from "react";
import Input from "../../CommonComponent/Input";
import Button from "../../CommonComponent/Button";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../../../hooks/useForm";
import { profileParameterFormValidate } from "../DynamicProfile/profileParametersCommon";
import { useDispatch } from "react-redux";
import { addVersionProfiles } from "../../../../store/slice/versionSlice";
import { getByteSize } from "../../../../helpers/commonFunction";

const AssignVersionParameters = (props) => {
  const {
    profile_id,
    data,
    initialFValues,
    forms,
    setForms,
    valueType,
    valueTypeToByteSize,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const configOptions = [
    {
      value: "app",
      label: "App",
    },
    {
      value: "motor",
      label: "Motor",
    },
  ];

  const bytesOptions = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 4,
      label: 4,
    },
    // {
    //   value: 8,
    //   label: 8,
    // },
    // {
    //   value: 16,
    //   label: 16,
    // },
    // {
    //   value: 32,
    //   label: 32,
    // },
  ];

  // const [forms, setForms] = useState(
  //   data ? createParametersData(data[0]?.parametres) : [initialFValues]
  // );

  // Function to handle input changes
  const handleChange = (index, name, value) => {
    const updatedForms = [...forms];
    if (name === "valueType") {
      if (
        value.label !== "VESC_TX_UNDEFINED" &&
        value.label !== "VESC_TX_UINT8" &&
        value.label !== "VESC_TX_UINT16" &&
        value.label !== "VESC_TX_UINT32"
      ) {
        updatedForms[index]["signed"] = true;
        updatedForms[index]["byteSize"] = getByteSize(
          valueTypeToByteSize,
          value.label
        );
      } else {
        updatedForms[index]["signed"] = false;
        updatedForms[index]["byteSize"] = getByteSize(
          valueTypeToByteSize,
          value.label
        );
      }
    }
    updatedForms[index][name] = value;
    setForms(updatedForms);

    const newErrors = [...errors];
    newErrors[index] = validateForm(updatedForms[index]);
    setErrors(newErrors);
  };

  const validateForm = (form) => {
    const tempErrors = {};
    if (!form.name) tempErrors.name = "Name is required.";
    if (!form.byteSize) tempErrors.byteSize = "Byte size is required.";
    if (!form.configPart) tempErrors.configPart = "Config part is required.";
    if (!form.valueType) tempErrors.valueType = "Value type is required.";
    if (!form.value) tempErrors.value = "Value is required.";
    return tempErrors;
  };

  const validateAllForms = () => {
    const allErrors = forms.map(validateForm);
    setErrors(allErrors);
    return allErrors.every(
      (formErrors) => Object.keys(formErrors).length === 0
    );
  };

  const addNewForm = () => {
    const newErrors = forms.map((form) => validateForm(form));
    const hasErrors = newErrors.some((error) => Object.keys(error).length > 0);

    if (!hasErrors && validateAllForms()) {
      setForms([...forms, initialFValues]);
      setErrors([...errors, {}]);
    } else {
      setErrors(newErrors);
    }
  };

  const removeForm = (index) => {
    const updatedForms = [...forms];
    updatedForms.splice(index, 1);
    setForms(updatedForms);

    const updatedErrors = [...errors];
    updatedErrors.splice(index, 1);
    setErrors(updatedErrors);
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return profileParameterFormValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = () => {
    if (validateAllForms()) {
      const totalByteSize = forms.reduce((sum, item) => {
        return sum + (item.byteSize?.value || 0);
      }, 0);
      const properties = forms.map((item, index) => ({
        id: index + 1,
        name: item.name || "",
        valueType: item.valueType?.value,
        signed: item.signed || false,
        byteSize: item.byteSize?.value || 0,
        value: item.value || "",
        configPart: item.configPart?.value || "",
      }));

      const payload = {
        profiles: [
          {
            profile_id: profile_id,
            total_bytes: totalByteSize,
            parametres: properties,
          },
        ],
      };

      dispatch(
        addVersionProfiles({
          data: { id: params.id, data: payload },
          onSuccess: (res) => {
            navigate(-1);
          },
          onFailure: () => {},
        })
      );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        {forms.map((form, index) => (
          <div key={index} className="assign_from_row mx-0 my-2">
            <div className="project_edit_feature_input mt-0">
              <Input
                className="project_edit_feature_input"
                type="text"
                placeholder="Enter Name"
                label="Name"
                id={`name-${index}`}
                name="name"
                value={form.name}
                onChange={(e) => {
                  handleChange(index, "name", e.target.value);
                  handleInputChange(e);
                }}
              />
              {errors[index]?.name && (
                <span className="err_text">{errors[index]?.name}</span>
              )}
            </div>

            <div className="project_edit_feature_input">
              <h4 className="project_text_area_label">Value Type</h4>
              <Select
                classNamePrefix="project_common_select_input"
                placeholder="Value type..."
                id={`valueType-${index}`}
                name="valueType"
                value={form.valueType}
                onChange={(selectedOption) => {
                  handleChange(index, "valueType", selectedOption);
                  handleInputChange(selectedOption);
                }}
                options={valueType}
              />

              {errors[index]?.valueType && (
                <span className="err_text">{errors[index]?.valueType}</span>
              )}
            </div>

            <div className="project_edit_feature_input">
              <h4 className="project_text_area_label">Byte size</h4>
              <Select
                classNamePrefix="project_common_select_input"
                placeholder="Byte size..."
                id={`byteSize-${index}`}
                name="byteSize"
                value={form.byteSize}
                onChange={(selectedOption) => {
                  handleChange(index, "byteSize", selectedOption);
                  handleInputChange(selectedOption);
                }}
                options={bytesOptions}
              />

              {errors[index]?.byteSize && (
                <span className="err_text">{errors[index]?.byteSize}</span>
              )}
            </div>
            <div className="project_edit_feature_input mt-0">
              <Input
                className="project_edit_feature_input"
                type="text"
                placeholder="Enter value"
                label="Value"
                id={`value-${index}`}
                name="value"
                value={form.value}
                // onChange={(e) =>
                //   handleInputChange(index, "value", e.target.value)
                // }
                onChange={(e) => {
                  handleChange(index, "value", e.target.value);
                  handleInputChange(e);
                }}
              />
              {errors[index]?.value && (
                <span className="err_text">{errors[index]?.value}</span>
              )}
            </div>
            <div className="project_edit_feature_input">
              <h4 className="project_text_area_label">Config part</h4>
              <Select
                classNamePrefix="project_common_select_input"
                placeholder="Config part..."
                id={`configPart-${index}`}
                name="configPart"
                value={form.configPart}
                onChange={(selectedOption) => {
                  handleChange(index, "configPart", selectedOption);
                  handleInputChange(selectedOption);
                }}
                options={configOptions}
              />
              {errors[index]?.configPart && (
                <span className="err_text">{errors[index]?.configPart}</span>
              )}
            </div>

            <div className="mt-3 ms-3">
              <h4 className="project_text_area_label">Signed</h4>
              <div className="project_common_form_check_box force_input">
                <input
                  type="checkbox"
                  // id={`signed-${index}`}
                  // name="signed"
                  checked={form.signed}
                  onChange={(e) => {
                    handleChange(index, "signed", e.target.checked);
                    handleInputChange(e);
                  }}
                  label="Signed"
                />
              </div>
              {errors[index]?.signed && (
                <span className="err_text">{errors[index]?.signed}</span>
              )}
            </div>

            <Button
              // className="remove-form-btn"
              buttonClassName="remove-form-btn"
              text="Remove"
              onClick={() => removeForm(index)}
            />
          </div>
        ))}
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          text="Add More"
          onClick={addNewForm}
        />
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          onClick={handleSubmit}
          text="Submit"
        />
      </div>
    </>
  );
};

export default AssignVersionParameters;
