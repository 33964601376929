import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../CommonComponent/CommonHeading";
import Loader from "../../CommonComponent/Loader";
import { backBtnArrow } from "../../../../icons";
import { getProfileDetails } from "../../../../store/slice/profileSlice";
import ViewVersionProfileDetails from "../DynamicProfile/ViewVersionProfileDetails";
import {
  editVersionProfiles,
  getVersion,
} from "../../../../store/slice/versionSlice";
import { Modal } from "react-bootstrap";
import DeleteVersionModal from "../../Modals/DeleteVersionModal";
import { toast } from "react-toastify";

const ViewVersionProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, parametres, profile, total_bytes } = location.state.item;
  const version_id = location.state.version_id;

  const profileDetail = useSelector((state) => state.profile.profileDetail);
  const VersionList = useSelector((state) => state.version?.versionList);
  const loading = useSelector((state) => state.version.loader);
  const [parameterList, setParameterList] = useState(parametres || []);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [showDeleteVerProfPara, setShowDeleteVerProfPara] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(false);
  const handleDeleteVerProfModal = (item) => {
    if (item) {
      setDeleteModalId(item.id);
    } else {
      setDeleteModalId("");
    }
    setShowDeleteVerProfPara(!showDeleteVerProfPara);
  };

  const handleDeleteVersionProfile = () => {
    const result = parameterList.filter((item) => item.id !== deleteModalId);
    setParameterList(result);
    deleteProfParameter(result);
  };

  const deleteProfParameter = (list) => {
    const totalByteSize = list.reduce((sum, item) => {
      return sum + (item.byteSize || 0);
    }, 0);
    const properties = list.map((item, index) => ({
      id: index + 1,
      name: item.name || "",
      valueType: item.valueType || "",
      signed: item.signed || false,
      byteSize: item.byteSize || 0,
      value: item.value || "",
      configPart: item.configPart || "",
    }));

    const payload = {
      parametres: properties,
      total_bytes: totalByteSize,
    };

    dispatch(
      editVersionProfiles({
        data: { id: id, data: payload },
        onSuccess: (res) => {
          handleDeleteVerProfModal();
          navigate(-1);
          toast.success("Parameter deleted successfully!", {
            autoClose: 3000,
          });
        },
        onFailure: () => {},
      })
    );
  };

  const handleSubmit = () => {
    const totalByteSize = parameterList.reduce((sum, item) => {
      return sum + (item.byteSize || 0);
    }, 0);

    const properties = parameterList.map((item, index) => ({
      id: index + 1,
      name: item.name || "",
      valueType: item.valueType || "",
      signed: item.signed || false,
      byteSize: item.byteSize || 0,
      value: item.value || "",
      configPart: item.configPart || "",
    }));
    const payload = {
      parametres: properties,
      total_bytes: totalByteSize,
    };

    dispatch(
      editVersionProfiles({
        data: { id: id, data: payload },
        onSuccess: (res) => {},
        onFailure: () => {},
      })
    );
  };

  useEffect(() => {
    dispatch(getVersion({ start: 0, limits: 10 }));
  }, []);

  useEffect(() => {
    dispatch(getProfileDetails({ data: params.id }));
  }, [params]);

  useEffect(() => {
    const result =
      VersionList && VersionList.length > 0 && Array.isArray(VersionList)
        ? VersionList.filter((item) => item.id === Number(version_id))
        : "";
    setSelectedVersion(result);
  }, [VersionList]);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"VERSION PROFILE DETAILS"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
              <div className="project_multiple_tab">
                <Link
                  className="project_submit_bottom_btn comman_btn"
                  to={`/dtv-profile-parameter/add/1`}
                  state={{
                    parametres: parametres,
                    total_bytes: total_bytes,
                    profile: profile,
                    editId: id,
                    version_id: version_id,
                  }}
                >
                  Add parameter
                </Link>
              </div>
            </div>
          </div>
          <ViewVersionProfileDetails
            editId={id}
            profile={profile}
            data={profileDetail}
            parametres={parametres}
            version_id={version_id}
            total_bytes={total_bytes}
            handleSubmit={handleSubmit}
            parameterList={parameterList}
            selectedVersion={selectedVersion}
            setParameterList={setParameterList}
            handleDeleteVerProfModal={handleDeleteVerProfModal}
          />
          <Modal
            className="modal_main_component modal_background_content"
            show={showDeleteVerProfPara}
            onHide={handleDeleteVerProfModal}
            centered
          >
            <DeleteVersionModal
              deleteModalId={deleteModalId}
              title={"Delete profile parameter"}
              description={"you want to delete this version profile parameter!"}
              closeDeleteModal={handleDeleteVerProfModal}
              deleteVehHandler={handleDeleteVersionProfile}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default ViewVersionProfilePage;
