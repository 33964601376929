import moment from "moment";
import React from "react";
import {
  defaultTimeZone,
  findValueTypeByValue,
} from "../../../../helpers/commonFunction";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { deleteIcon } from "../../../../icons";
import { Link } from "react-router-dom";
import Button from "../../CommonComponent/Button";

const ViewVersionProfileDetails = ({
  editId,
  profile,
  version_id,
  parametres,
  total_bytes,
  handleSubmit,
  parameterList,
  selectedVersion,
  setParameterList,
  handleDeleteVerProfModal,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      parameterList,
      result.source.index,
      result.destination.index
    );
    setParameterList(items);
  };

  const tableHeaders = [
    "ID",
    "NAME",
    "BYTE Size",
    "CONFIG TYPE",
    "VALUE TYPE",
    "VALUE",
    "ACTION",
  ];

  const valueType = [
    { label: "VESC_TX_UNDEFINED", value: 0 },
    { label: "VESC_TX_UINT8", value: 1 },
    { label: "VESC_TX_INT8", value: 2 },
    { label: "VESC_TX_UINT16", value: 3 },
    { label: "VESC_TX_INT16", value: 4 },
    { label: "VESC_TX_UINT32", value: 5 },
    { label: "VESC_TX_INT32", value: 6 },
    { label: "VESC_TX_DOUBLE16", value: 7 },
    { label: "VESC_TX_DOUBLE32", value: 8 },
    { label: "VESC_TX_DOUBLE32_AUTO", value: 9 },
  ];

  return (
    <>
      <div className="create_from_row mb-3">
        <div className="project_view_list_item">
          <h2>Version name</h2>
          <p>
            {selectedVersion && selectedVersion[0].version
              ? selectedVersion[0].version
              : "-"}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Profile name</h2>
          <p>{profile.name ? profile.name : "-"}</p>
        </div>
        <div className="project_view_list_item">
          <h2>Created At</h2>
          <p>
            {profile.created_at
              ? moment
                  .utc(profile.created_at, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("Do MMMM YYYY")
              : "-"}
          </p>
        </div>
        <div className="project_view_list_item">
          <h2>Total bytes</h2>
          <p>{total_bytes ? total_bytes : 0}</p>
        </div>
      </div>
      <div>
        <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="custom_data_table_content"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {!parameterList || parameterList?.length === 0 ? (
                  <p className="no_data_found">No data Found</p>
                ) : (
                  <table className="custom_data_table">
                    <thead className="custom_data_table_head">
                      <tr>
                        {tableHeaders.map((item, index) => {
                          return (
                            <th
                              className="custom_data_table_heading text-center"
                              key={index}
                            >
                              {item}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="custom_data_table_body">
                      {parameterList &&
                        parameterList.length > 0 &&
                        Array.isArray(parameterList) &&
                        parameterList?.map((item, i) => {
                          return (
                            <>
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}`}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    className="custom_data_table_row"
                                    provided={provided}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={item.id}
                                  >
                                    <td className="custom_data_table_item table_item">
                                      {i + 1}
                                    </td>
                                    <td className="custom_data_table_item table_item">
                                      {item.name}
                                    </td>
                                    <td className="custom_data_table_item table_item">
                                      {item.byteSize}
                                    </td>
                                    <td className="custom_data_table_item table_item">
                                      {item.configPart}
                                    </td>
                                    <td className="custom_data_table_item table_item">
                                      {item.valueType == 0
                                        ? "VESC_TX_UNDEFINED"
                                        : findValueTypeByValue(
                                            valueType,
                                            item.valueType
                                          ).label}
                                    </td>
                                    <td className="custom_data_table_item table_item">
                                      {item.value}
                                    </td>
                                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                                      <div className="custom_data_table_view_edit_btn_item_row">
                                        <Link
                                          className="comman_btn"
                                          to={`/dtv-profile-parameter/edit/${item.id}`}
                                          state={{
                                            item: item,
                                            isEdit: true,
                                            parametres: parametres,
                                            total_bytes: total_bytes,
                                            profile: profile,
                                            editId: editId,
                                            version_id: version_id,
                                          }}
                                        >
                                          Edit
                                        </Link>
                                        <button
                                          aria-label="edit"
                                          className="custom_data_tabl_delete_item_btn"
                                          onClick={(e) =>
                                            handleDeleteVerProfModal(item)
                                          }
                                        >
                                          {deleteIcon}
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {parameterList && parameterList.length > 0 && (
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          text="Save"
          onClick={() => handleSubmit()}
        />
      )}
    </>
  );
};

export default ViewVersionProfileDetails;
